import NavBar from "../components/Navbar/NavBar";
import Footer from "../components/Footer";
import img from "../images/heroImg.jpg";
import classImg from "../images/kindergarten/classtime.png";
import kidImg from "../images/kindergarten/kindergarten1.png";

const Kindergarten = () => {
  return (
    <>
      <div>
        <NavBar />
      </div>
      <br />
      <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6" id="section1">
        <h1 className="mb-5 text-3xl md:text-5xl font-bold text-blue-900">
          奧斯汀美語 - 平鎮文化分校 幼兒美語
        </h1>
        <div
          className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left bg-gray-100"
          data-aos="fade-up"
        >
          <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center">
            <img alt="card img" className="rounded-lg float-right" src={img} />
          </div>

          <div
            className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8"
            data-aos="zoom-in"
            data-aos-delay="500"
          >
            <h3 className="text-xl text-blue-900 font-bold">
              幼兒園成立於1993年，至今已有近30年的辦學經驗，心象園幼兒園秉持教育理念，園所課程設計中融入日常生活教育、品格養成教育、數的概念、感官課程、中英文語文教育、親子共讀繪本活動...，幫助孩子建立良好的習慣與品格，培養孩子尊重他人、獨立自主與建立良好衛生習慣的觀念。
            </h3>
            <br />
          </div>
        </div>
      </div>

      {/* 独立显示的文字区域 */}
      <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6 py-12" id="section2">
        <div
          className="flex justify-center text-center lg:text-left"
          data-aos="zoom-in"
          data-aos-delay="500"
        >
          <h3 className="text-xl text-blue-900 font-bold">
            提供優質的學習環境，讓孩子在正向鼓勵與愛的環境下快樂成長，老師針對每個孩子不同的特點，調整孩子的學習步調，建立孩子喜歡閱讀的習慣、開發孩子潛在能力與創造力、培養孩子口說能力與自信、訓練孩子肢體協調能力，讓孩子儘早適應學習生活技能與融入團體生活，心象園幼兒園竭誠歡迎您家的小寶貝一同加入我們!
          </h3>
        </div>
      </div>

      {/* 独立显示的图片区域 */}
      <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6" id="section3">
        <h2 className="text-2xl text-blue-900 font-bold">
          幼兒園亦提供多項幼兒才藝課程：
        </h2>
        <div className="flex justify-center py-8" data-aos="fade-up">
          <img alt="card img" className="rounded-lg" src={classImg} />
        </div>
        <div className="flex justify-center py-8" data-aos="fade-up">
          <img alt="card img" className="rounded-lg" src={kidImg} />
        </div>
      </div>

      {/* 并排显示的两个视频区域 */}
      <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6" id="section4">
        <div className="flex justify-center space-x-4" data-aos="fade-up">
          <div className="w-1/2">
            <iframe
              className="w-full h-64 rounded-lg"
              src="https://www.youtube.com/embed/pEI1-qsoZK4"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
          <div className="w-1/2">
            <iframe
              className="w-full h-64 rounded-lg"
              src="https://www.youtube.com/embed/UJJIGCLNKv4"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Kindergarten;

import React from "react";
import NavBar from "../components/Navbar/NavBar";
import Footer from "../components/Footer";

import heroImg from "../images/heroImg.jpg";

const HomePage = () => {
  return (
    <>
      <div>
        <NavBar />
      </div>
      <div
        className="m-auto overflow-hidden mx-4 mt-8 lg:mt-4 p-2 md:p-12 h-5/6"
        data-aos="zoom-in"
      >
        <div className="flex flex-col lg:flex-col py-8 justify-between items-center text-center lg:text-left">
          <div
            className="lg:w-1/2 flex flex-col justify-center"
            data-aos="zoom-in"
            data-aos-delay="200"
          >
            <h1 className="mb-5 text-3xl md:text-5xl font-bold text-blue-900">
              奧斯汀美語-文化分校-桃園英語補習
            </h1>
            <p className="text-xl font-semibold tracking-tight mb-5 text-gray-500">
              奧斯汀美語補習班位於桃園平鎮，是您孩子學習英語的最佳選擇。作為專業的桃園英語補習班，我們提供全方位的聽、說、讀、寫訓練，讓學生能夠在輕鬆的環境中有效提升英文能力。我們致力於讓每個孩子都能在歡樂的氛圍中成長，並打好扎實的語言基礎。
              不論是尋找桃園英文補習還是平鎮補習班，奧斯汀美語都是值得信賴的選擇。我們的教學課程設計，適合不同年齡層的學生，並強調個別化教學，確保每個孩子都能得到適合自己的學習計劃。
              此外，我們也是桃園安親班的理想選擇，不僅提供課後輔導，更加注重孩子們的全面發展，讓家長可以安心，孩子快樂學習。如果您正在找尋一個能夠提升孩子英文能力的平鎮英語補習班，歡迎聯繫我們，讓您的孩子在奧斯汀美語展翅高飛，邁向國際。
            </p>
            <div
              className="flex justify-center lg:justify-end w-full lg:w-1/2"
              data-aos="fade-up"
              data-aos-delay="700"
            >
              <img
                alt="Hero"
                className="rounded-lg duration-1000 w-full h-auto object-contain"
                src={heroImg}
              />
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default HomePage;
